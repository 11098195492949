.lds-dual-ring {
  display: inline-block;
  width: 25px;
  height: 25px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  margin: 0px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-loader {
  position: relative;
}
.btn-loader .lds-dual-ring {
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translatey(-50%);
}

.load {
  position: relative;
}

@media screen and (max-width: 767px) {
  .btn-loader .lds-dual-ring {
    top: 10px;
  }
}
