nav .logo-desktop {
  position: relative;
  min-width: 300px;
}

nav .logo-desktop a.navbar-brand {
  position: absolute;
  top: -40px;
  left: 0;
  width: 250px;
  padding: 20px 26px;
  z-index: 10;
}

nav.navbar {
  background-color: var(--white);
  padding: 0;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);
}

nav a.navbar-brand {
  background: var(--primary);
  padding: 5px 20px;
  margin: 0 30px;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 50%);
}
nav .mobile-show {
  display: none;
}

.top-header + nav li.nav-item {
  display: flex;
  align-items: center;
  padding: 0 20px;
  padding: 1rem 0.5rem;
}
.top-header + nav a.nav-link {
  color: var(--primary);
  font-weight: 500;
  border-bottom: 2px solid transparent;
  padding: 0;
  margin: 0.5rem 0.5rem;
}

.top-header + nav a.nav-link:hover {
  border-bottom: 2px solid var(--yellow);
  color: var(--primary);
}
ul.top-link > li > a:hover,
.logout:hover {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .top-header + nav a.nav-link {
    margin: 0.5rem 0;
  }
}
@media screen and (max-width: 1200px) {
  nav .logo-desktop a.navbar-brand {
    top: -36px;
    width: 232px;
    padding: 10px 11px;
  }
  .top-header + nav li.nav-item {
    padding: 0.5rem 4px;
  }
  .top-header + nav a.nav-link {
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1040px) {
  nav .logo-desktop {
    position: relative;
    min-width: 200px;
  }

  nav .logo-desktop a.navbar-brand {
    top: -32px;
    left: 4px;
    width: 189px;
    padding: 12px;
    text-align: center;
    margin: 0;
  }
  nav .logo-desktop a.navbar-brand > img {
    width: 75%;
  }
}

@media screen and (max-width: 992px) {
  .container-fluid.bg-mobile {
    background: #723c14;
    padding: 8px 16px;
    color: #fff;
  }
  nav a.navbar-brand {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
  nav .mobile-show {
    display: block;
  }
  nav .mobile-show img {
    width: 70%;
  }
  button.navbar-toggler {
    border: none;
  }
  span.navbar-toggler-icon {
    filter: invert(1);
  }
  .top-header + nav li.nav-item {
    border-top: 1px solid #ffffff2e;
    padding: 4px 0px;
  }
  .top-header + nav a.nav-link {
    color: #fff !important;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .navbar-nav .nav-link.active {
    color: #fff;
  }
  .mobile-m {
    margin-top: 25px;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
}
