.gallery-banner {
  background-image: url(../../assets/gallery-banner.jpg);
  height: 60vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  background-position: center;
  margin-bottom: 50px;
}
section.galley {
  background: #723c140d;
  padding-bottom: 4rem;
}

.g-title {
  font-size: 40px;
  font-weight: 600;
  margin: auto;
  text-align: center;
  padding: 3rem 0;
  margin-top: 2rem;
}
.react-photo-gallery--gallery img {
  object-fit: cover;
  object-position: center;
}
.frame img {
  width: 100%;
}

@media screen and (max-width: 1025px) {
  .gallery-banner {
    height: 25vh;
    margin-bottom: 10px;
  }
  .feature-title {
    font-size: 18px;
  }
  .g-title {
    font-size: 30px;
    padding: 1rem 0;
    margin-top: 1rem;
  }
}
