.top-header {
  background-color: var(--primary);
}
ul.top-link {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  margin-left: 8px;
}
ul.top-link > li {
  padding: 0.5rem 0.5rem 0.5rem 1.3rem !important;
}

ul.top-link > li > a,
ul.top-link > li > a:hover {
  text-decoration: none;
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  padding: 0 !important;
  margin: 0.5rem 0.5rem;
}
ul.top-link > .nav-item > .nav-link {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .top-header {
    background-color: var(--primary);
    border-bottom: 1px solid #ffffff40;
  }
  ul.top-link > li {
    padding: 5px !important;
  }
  ul.top-link > li > a {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  ul.top-link > li {
    padding: 0px !important;
  }
  ul.top-link > li > a {
    font-size: 10px;
  }
}
