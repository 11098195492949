.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* form section start */
.select-box{
  width: 100%;
  padding: 15px;
  
}
input.form-control, select.form-control.form-select{
  padding: 12px;
}

label.form-label.select-label {
  margin-left: -12px;
}
/* form section end */
.card-registration p {
  text-align: center;
  position: relative;
  top: 34px;
  color: red;
  font-weight: 700;
  letter-spacing: 1px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1300px) {
  .bg-speech-image{
    background-color: #fff;
    background-position-x: 37% !important;
  }
}


.error {
  color: red;
  font-size: 14px;
}

.feature-title{
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 800;
  color: #f0f0f0;
  backdrop-filter: blur(20px);
  padding: 0.75rem 1.5rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width:1200px) {
  .feature-title {
    font-size: 30px;
}
  
}


@media screen and (max-width:992px) {

.feature-title {
    font-size: 20px;
  padding: 0.75rem 1rem;

}
  
}
@media screen and (max-width:480px) {

  .feature-title {
      font-size: 14px !important;
  }
    
  }
