footer {
  padding-top: 2rem;
  border-bottom: 1rem solid var(--primary);
}
.logo {
  margin-bottom: 20px;
}
.login {
  text-align: right;
}
.login .btn {
  width: 250px;
  background-color: var(--primary);
  border: none;
  color: var(--white);
  font-size: 16px;
  padding: 1rem;
  font-weight: 600;
  border-radius: 0px;
}
.login .btn:hover {
  background-color: var(--primary);
}
h6.title {
  color: var(--gray);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 0.9rem !important;
}
p.description {
  text-align: justify;
  padding-right: 50px;
}

footer ul.nav li.nav-item a.nav-link {
  text-transform: uppercase;
  color: var(--gray) !important;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
}
footer ul.nav li.nav-item a.nav-link:hover {
  color: var(--yellow) !important;
}
.social-icon .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  margin-left: 4rem;
}
.social-icon .nav li i {
  color: #959595;
  font-size: 25px;
}
.social-icon .nav li i:hover {
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}

.footer-bottom {
  border-top: 1px solid #e9e9e9;
  text-align: center;
  padding: 1.5rem 0;
  margin-top: 1rem;
}
.copyright-contant {
  margin-bottom: 0;
  font-size: 14px;
}

/* Meadia query */
@media screen and (max-width: 1400px) {
  .social-icon .nav{
    margin-left: 1rem;
  }
}
@media screen and (max-width: 1400px) {
  .social-icon .nav{
    gap: 1rem;
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  h6.title.about {
    margin-top: 1rem;
  }
  .login .btn {
    width: initial;
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
  .login {
    text-align: left;
    margin-top: 1rem;
    font-size: 14px;
  }
  .social-icon .nav {
    margin-left: 0rem;
    justify-content: flex-start;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  .social-icon .nav li i {
    font-size: 20px;
  }
  p.description {
    padding-right: 0;
  }
  .alumini-banner{
    margin-bottom: 20px !important;
  }
}
